<template>
  <div
    class="period_picker"
    :class="{
      isMax: selectedYear === maxYear,
      isMin: selectedYear === minYear,
    }"
  >
    <div class="period_picker__years">
      <div class="period_picker__years_selected">
        {{ selectedYear }}
      </div>
    </div>

    <div class="period_picker__days_of_week">
      <span
        class="period_picker__day_of_week"
        v-for="day of daysOfWeek"
        :key="day"
      >
        {{ day }}
      </span>
    </div>

    <div class="period_picker__calendar" ref="calendar" />

    <transition name="y">
      <div class="period_picker__value" v-if="showDateInfo">
        <button class="close" @click="showDateInfo = false">
          <img src="@/assets/img/icons/close.png" alt="" />
        </button>
        <span class="period_picker__value__start">{{ updatedDate.start }}</span>
        <span class="period_picker__value--divider">-</span>
        <span class="period_picker__value__end">{{ updatedDate.end }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import Litepicker from "litepicker";
import {
  getCurrentMonth,
  getCurrentYear,
  getToday,
} from "@/utilles/date/getCurrentDate";

import { daysOfWeek } from "@/constants/date/daysOfWeek";
import { formatDate } from "@/utilles/date/formatDate";
import { APP_LOCALE, YEARS_COUNT } from "@/constants/base";
import { icons } from "@/utilles/icons";

export default {
  name: "AppPeriodPicker",
  props: {
    type: { type: String, default: "period" },
    selectedPeriod: {
      type: Array,
      default: () => {
        const now = new Date(Date.now()).toISOString();
        return [now, now];
      },
    },
  },
  data() {
    return {
      preselectDate: null,
      startDate: this.selectedPeriod[0],
      endDate: this.selectedPeriod[1],
      calendarInstance: null,
      pickerHeight: 8500,
      monthCount: 12,
      prevBtn: null,
      nextBtn: null,
      showDateInfo: true,
      selectedYear: new Date(this.selectedPeriod[0]).getFullYear(),
      currentMonth:
        getCurrentMonth() < 10 ? `0${getCurrentMonth()}` : getCurrentMonth(),

      daysOfWeek: daysOfWeek,
    };
  },

  computed: {
    updatedDate() {
      const start = formatDate(this.startDate, {
        includeYear:
          new Date(this.startDate).getFullYear() !== getCurrentYear(),
      });

      const endYear = new Date(this.endDate).getFullYear();

      const end = !this.endDate
        ? " "
        : formatDate(this.endDate, {
            includeYear: endYear !== getCurrentYear(),
          });

      return {
        start,
        end,
      };
    },
    minYear() {
      return new Date(
        `${this.currentMonth}.2.${getCurrentYear() - 3}`
      ).getFullYear();
    },

    maxYear() {
      return getCurrentYear();
    },

    oneMonthHeight() {
      return this.pickerHeight / this.monthCount;
    },
  },

  watch: {
    startDate() {
      this.monthSelectHandler();
    },

    endDate() {
      this.monthSelectHandler();
    },

    selectedPeriod() {
      this.setDateToCalendar();
    },
  },

  methods: {
    setDate(start, end) {
      this.calendarInstance.setDateRange(start, end);
    },
    goToYear() {
      this.calendarInstance.gotoDate(`01/01/${this.selectedYear}`, 0);
    },
    switchYear(status) {
      const button = status === "increment" ? this.nextBtn : this.prevBtn;

      if (button) {
        button.click();
      }
    },

    destroy() {
      if (this.calendarInstance) {
        this.calendarInstance.destroy();
      }
    },

    initCalendar() {
      const maxMonth = getCurrentMonth() + 1;
      const currentYear = getCurrentYear();
      const today = getToday();
      let month = maxMonth < 10 ? `0${maxMonth}` : maxMonth;

      const minYear = currentYear - YEARS_COUNT;
      const maxYear = currentYear;

      const min = new Date(`${month}/2/${minYear}`);
      const max = new Date(`${month}/${today + 1}/${maxYear}`);

      this.minDate = min;
      this.maxDate = max;

      this.calendarInstance = new Litepicker({
        element: this.$refs.calendar,
        inlineMode: true,
        numberOfMonths: 12,
        switchingMonths: 12,
        singleMode: false,
        minDate: min.toISOString(),
        maxDate: max.toISOString(),
        scrollToDate: true,
        buttonText: {
          ...icons,
        },

        lang: APP_LOCALE,

        setup: (picker) => {
          picker.on("selected", (date1, date2) => {
            this.startDate = date1.dateInstance;
            this.endDate = date2.dateInstance;
          });
          picker.on("change:month", (date) => {
            this.selectedYear = date.dateInstance.getFullYear();
          });
          picker.on("render", (ui) => {
            this.prevBtn = ui.querySelector(".button-previous-month");
            this.nextBtn = ui.querySelector(".button-next-month");
            this.pickerHeight = ui.getBoundingClientRect().height;
          });
        },
      });

      this.goToYear();

      this.$nextTick(() => {
        this.pickerHeight =
          this.calendarInstance.ui.getBoundingClientRect().height;

        this.setDateToCalendar();
      });
    },
    monthSelectHandler() {
      this.$emit("setDate", {
        type: this.type,
        value: [this.startDate, this.endDate],
      });
    },

    setDateToCalendar() {
      if (!this.calendarInstance) {
        return;
      }

      const startDateInstance = new Date(this.startDate);
      const yearOfStartDate = startDateInstance.getFullYear();

      if (yearOfStartDate === this.selectedYear) {
        const selectedMonthIdx = startDateInstance.getMonth();

        console.log("selectedMonthIds", selectedMonthIdx);

        if (selectedMonthIdx !== 0) {
          const scrollHeight =
            (this.monthCount - (this.monthCount - selectedMonthIdx)) *
            this.oneMonthHeight;

          window.scrollTo({ top: scrollHeight, behavior: "smooth" });
        }
      }

      this.calendarInstance.setDateRange(...this.selectedPeriod);
    },
  },
  mounted() {
    this.initCalendar();
  },
  destroyed() {
    this.calendarInstance.destroy();
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss">
:root {
  --litepicker-container-months-color-bg: transparent !important;
  --litepicker-day-color: rgba(235, 235, 245, 0.5) !important;
  --litepicker-day-color-hover: #1debba !important;
  --litepicker-is-locked-color: rgba(235, 235, 245, 0.25) !important;
}

$headerTop: 46px;
$picker-in-range-color: $app-primary-light-color;

$item-padding: $app-layout-x-padding;

@media (max-width: 345px) {
  $item-padding: 10px;
}
.period_picker__years {
  position: fixed;
  top: $headerTop + 32px;
  background: $app-primary-blue-color;
  z-index: 999;
  width: 100%;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-bottom: 1px solid #f1f1f1;

  &_selected {
    color: $app-primary-font-color;
  }
}
.y-enter-active,
.y-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.y-enter,
.y-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
.period_picker__calendar {
  padding-top: 50px;
}
.period_picker {
  &__days_of_week {
    display: flex;
    justify-content: space-between;

    position: sticky;
    top: 117px;
    z-index: 9999;
    background: $app-primary-blue-color;
  }

  &__day_of_week {
    padding: $item-padding;
    color: $app-primary-font-color;
    font-size: 1rem;

    @media (max-width: 345px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .month-item:not(:first-child) {
    .button-previous-month,
    .button-next-month {
      display: none;
    }
  }

  .month-item:first-child {
    .button-previous-month,
    .button-next-month {
      position: fixed;
      top: $headerTop + 41px;
      left: $app-layout-x-padding;
      z-index: 9999;
      color: $app-primary-color !important;

      svg {
        fill: currentColor;
      }
    }

    .button-next-month {
      left: auto;
      right: $app-layout-x-padding;
    }
  }

  .litepicker {
    width: 100%;

    .container__main,
    .container__months,
    .month-item {
      width: 100%;
      padding: 0;
    }

    .button-previous-month,
    .button-next-month {
      //display: none;
      //opacity: 0;
      visibility: visible !important;
    }

    .month-item-header {
      text-align: left;
      padding: 24px $app-layout-x-padding 10px;
    }
    .month-item-name {
      text-transform: capitalize;
    }
    .month-item-name,
    .month-item-year {
      font: 500 fontSize(xl) / 1 $app-font-family;
      color: $app-primary-font-accent-color;
    }

    .container__months {
      box-shadow: none !important;
      .month-item-weekdays-row {
        display: none;
      }
    }

    .container__days {
      & > * {
        width: calc(100% / 7);
        padding: 10px $item-padding;

        font-family: $app-font-family;
        font-size: fontSize(lg);
        line-height: 1.125;
        position: relative;
        z-index: 1;

        &.day-item:hover {
          box-shadow: none;
        }

        &.is-in-range {
          background: $app-primary-blue-color;

          color: $app-green-color;
        }

        &.is-start-date,
        &.is-end-date {
          background: transparent;
          &:after {
            content: "";
            width: 63%;
            height: 100%;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            z-index: -2;
            background: $app-primary-blue-color;
            position: absolute;
            top: 0;
            right: 0;
          }

          &:before {
            content: "";
            width: 38px;
            height: 38px;
            background: $app-green-color;
            // border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }

        &.is-end-date {
          &:after {
            right: auto;
            left: 0;
          }
        }

        &.is-start-date.is-end-date {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .period_picker__value {
    position: fixed;
    bottom: 80px;
    z-index: 999;

    display: flex;
    justify-content: center;

    width: 100%;
    padding-bottom: 16px;
    padding-top: 30px;

    color: $app-white-color;

    background: $app-primary-blue-dark-color;

    .close {
      position: absolute;
      font-size: fontSize(lg);
      top: 12px;
      right: 12px;
      width: 1em;
      height: 1em;
      padding: 0;
      background: transparent;
      border: none;
      outline: none;
      box-shadow: none;

      img {
        width: 0.7em;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &__start,
    &__end {
      display: block;
      text-align: center;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    &--divider {
      padding: 0 10px;
    }
  }
}

.isMax {
  .button-next-month {
    opacity: 0.5;
    pointer-events: none;
  }
}

.isMin {
  .button-previous-month {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
