<template>
  <div class="category_page">
    <app-tabs-route
      :categoryName="$route.params.categoryName"
      :categoryType="$route.params.categoryType"
      :tabs="tabsList"
      :tabsTypes="typesList"
      :tabsNames="namesList"
      :selectedFilter="selectedFilterName"
      :selectedTabName="selectedTabName"
      :parent="$route.name"
      @select="selectTabHandler"
    >
      <template #betweenContent>
        <div class="content-inner">
          <horizontal-scroll-area class="projects_filters">
            <div class="projects_filters__wrapper">
              <app-badge-radio
                class="projects_filters__item"
                :key="id"
                v-for="{ title, id, name, selected } in filters"
                :name="`${category}-filter`"
                :id="id"
                :title="title"
                :checked="selected"
                @change="filterHandler(id, name)"
              />
            </div>
          </horizontal-scroll-area>
          <app-toolbar />
        </div>
      </template>

      <template #tabsContent>
        <div class="projects_wrapper">
          <app-list v-if="projectsList.length">
            <router-link
              v-for="{ name, id } in projectsList"
              :key="id"
              :to="{ name: 'project', params: { id, title: name } }"
            >
              <app-list-item>
                <p>{{ name }}</p>
              </app-list-item>
            </router-link>
          </app-list>

          <p class="empty" v-else>Нет данных</p>
        </div>
      </template>
    </app-tabs-route>
  </div>
</template>

<script>
import AppToolbar from "@/components/Layout/Toolbar";
import AppBadgeRadio from "@/components/ui/BadgeRadio";
import HorizontalScrollArea from "@/components/ui/HorizontalScrollArea";
import AppTabsRoute from "@/components/ui/Tabs/TabsRoute";
import AppList from "@/components/ui/List/AppList";
import AppListItem from "@/components/ui/List/AppListItem";
import { stagesIdByType } from "@/constants/enums/projects";
import { mapGetters } from "vuex";

export default {
  name: "Category",
  components: {
    AppListItem,
    AppList,
    AppTabsRoute,
    HorizontalScrollArea,
    AppBadgeRadio,
    AppToolbar,
  },
  data() {
    return {
      selectedFilter: null,
      selectedFilterName: null,
      selectedTabName: this.$route.params.categoryName,
      requestTimeout: null,
    };
  },

  // TODO
  created() {
    this.changeRouteHandler();
  },

  computed: {
    ...mapGetters(["selectedDate", "projectsList"]),
    category() {
      return this.$route.params.categoryType;
    },

    stageId() {
      return (
        this.filters.find(({ selected }) => selected)?.stageId ??
        stagesIdByType[this.category].stageId
      );
    },

    types() {
      return stagesIdByType[this.category].types || null;
    },

    statuses() {
      return stagesIdByType[this.category].statuses;
    },

    filters() {
      if (!this.statuses) {
        return [];
      }

      return this.statuses.map(
        ({
          id,
          name,
          title,
          statusId,
          stageId = null,
          isPortalDiscussion = false,
          extraParams = {},
        }) => {
          return {
            id,
            name,
            title,
            statusId,
            stageId,
            isPortalDiscussion,
            selected: this.selectedFilter === id,
            extraParams,
          };
        }
      );
    },

    tabsList() {
      if (!this.types) {
        return null;
      }
      return this.types.map(({ title }) => title);
    },

    namesList() {
      if (!this.types) {
        return null;
      }
      return this.types.map(({ name }) => name);
    },

    typesList() {
      if (!this.types) {
        return null;
      }

      return this.types.map(({ typeId }) => typeId);
    },

    typeId() {
      if (!this.types || !this.selectedTabName) {
        return 0;
      }
      return this.types.find((type) => {
        return type.name === this.selectedTabName;
      }).typeId;
    },

    statusId() {
      return this.filters.find(({ selected }) => selected)?.statusId ?? 0;
    },

    isPortalDiscussion() {
      return (
        this.filters.find(({ selected }) => selected)?.isPortalDiscussion ??
        false
      );
    },

    extraParams() {
      const { extraParams } =
        this.filters.find(({ selected }) => selected) || {};

      return extraParams;
    },
  },

  watch: {
    // TODO
    $route() {
      this.changeRouteHandler();
    },
    extraParams: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },

    selectedDate() {
      this.fetchData();
    },

    statusId() {
      this.fetchData();
    },

    typeId() {
      this.fetchData();
    },

    stageId() {
      this.fetchData();
    },
  },

  methods: {
    changeRouteHandler() {
      if (!this.filters.length) {
        return;
      }

      this.selectedFilter = this.filters.find((filter) => {
        return filter.name === this.$route.params.subCategoryName;
      }).id;

      this.selectedFilterName = this.filters.find((filter) => {
        return filter.name === this.$route.params.subCategoryName;
      }).name;

      if (this.types) {
        this.selectedTabName = this.types.find((tabObject) => {
          return tabObject.name === this.$route.params.categoryName;
        }).name;
      }
    },
    setRoute(filterName) {
      this.$router.replace({
        path: `/category/${
          this.category
        }/${this.selectedTabName.toLowerCase()}/${filterName.toLowerCase()}`,
      });
    },
    selectTabHandler(tabName) {
      this.selectedTabName = tabName;
    },

    filterHandler(id, name) {
      this.setRoute(name);

      this.selectedFilter = id;
      this.selectedFilterName = name;
    },

    async fetchData() {
      clearTimeout(this.requestTimeout);
      this.requestTimeout = setTimeout(async () => {
        const { stageId, typeId, statusId, isPortalDiscussion } = this;

        const filter = {
          stageId,
          typeId,
          statusId,
          isPortalDiscussion,
          ...this.extraParams,
        };

        this.$store.commit("setCurrentPath", this.$route.path);

        await this.$store.dispatch("fetchProjectsList", filter);
      }, 300);
    },
  },

  async mounted() {
    this.loading = true;

    await this.fetchData();
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.projects_filters {
  &__item {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.projects_wrapper {
  display: flex;
  flex: 1;
  padding-bottom: 40px;

  .empty {
    text-align: center;
    width: 100%;
    align-self: center;
  }
}

.category_page {
  display: flex;
  flex: 1;
  .app_list__item {
    padding-right: 30px;
  }

  .app_tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
  }

  .content-inner {
    width: 100%;
    max-width: 100%;
  }
}

.projects_filters__wrapper {
  display: flex;
  padding: 2px;

  background-color: $app-primary-blue-color;

  border-radius: 9px;
}
</style>
