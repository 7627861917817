import { dashboardParams } from "../../constants/enums/dashboard";

export function dashboardParamsController(params) {
  const fullDasboardParams = {};

  for (let param in dashboardParams) {
    fullDasboardParams[param] = { ...params, ...dashboardParams[param] };
  }

  return fullDasboardParams;
}
