import { getCurrentYear } from "@/utilles/date/getCurrentDate";
import { YEARS_COUNT } from "@/constants/base";

let cashedYears = [];

export const years = () => {
  if (cashedYears.length) {
    return cashedYears;
  }

  const currentYear = getCurrentYear();

  let yearsList = [];
  for (let i = currentYear - YEARS_COUNT; i < currentYear + 1; i++) {
    yearsList.push(i);
  }

  cashedYears = yearsList;
  return yearsList;
};
