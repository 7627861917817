<template>
  <div class="diagrams_list__item">
    <swiper :options="swiperOptions" ref="swiperInstance">
      <swiper-slide v-for="tab in updatedChartData.tabs" :key="tab.tabType">
        <router-link :to="addDefaultRoute(tab.tabType)">
          <div class="diagrams_list__item_body">
            <app-bar-diagram
              :diagram-type="diagramType"
              :diagram-group-type="diagramTypeGroup(tab.tabType)"
              :series="activeSummary(tab).series"
              :categories-names="activeSummary(tab).titles"
              :colors-array="activeSummary(tab).colors"
              :fill-array="activeSummary(tab).fills"
            />
          </div>
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import {
  colorsByFill,
  projectStatusFills,
} from "@/constants/projects/projectStatuses";
import AppBarDiagram from "@/components/Charts/AppBarDiagram";
import {
  projectEnumsTotal,
  projectEnumsGroups,
} from "@/constants/enums/projects";

import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

const data = {
  titles: "dataTitle",
  fills: "fill",
  colors: "color",
  series: "value",
};

export default {
  name: "DashboardChart",
  components: { AppBarDiagram, Swiper, SwiperSlide },

  directives: {
    swiper: directive,
  },

  props: {
    chartData: { type: Object, required: true },
    routes: { type: Object },
  },

  data() {
    return {
      swiper: null,
      swiperOptions: {
        direction: "horizontal",
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
        },
      },

      updatedChartData: null,

      simplifiedTabs: this.chartData.tabs.map(({ tabType, tabTitle }, idx) => ({
        id: tabType,
        title: tabTitle,
        selected: idx === 0,
      })),
    };
  },
  watch: {
    chartData: {
      handler() {
        this.setUpdatedChartData();
      },

      deep: true,
    },
  },
  computed: {
    diagramType() {
      return projectEnumsTotal[this.chartData.type].toLowerCase();
    },
  },

  methods: {
    addDefaultRoute(categoryType) {
      return this.routes.innerRoutes[categoryType].innerRoutes[
        Object.keys(this.routes.innerRoutes[categoryType].innerRoutes)[0]
      ].route;
    },
    diagramTypeGroup(typeName) {
      return projectEnumsGroups[typeName].toLowerCase();
    },
    activeSummary(tab) {
      return tab.content.reduce((acc, dataItem) => {
        Object.entries(data).forEach(([key, value]) => {
          if (acc[key]) {
            if (value === "dataTitle") {
              acc[key].push(dataItem[value].toLowerCase());
            } else {
              acc[key].push(dataItem[value]);
            }
          } else {
            if (value === "dataTitle") {
              acc[key] = [dataItem[value].toLowerCase()];
            } else {
              acc[key] = [dataItem[value]];
            }
          }
        });

        return acc;
      }, {});
    },
    setUpdatedChartData() {
      this.updatedChartData = {
        ...this.chartData,
        tabs: this.chartData.tabs.map((tab) => {
          return {
            ...tab,
            content: tab.content.map((content) => {
              const fill = projectStatusFills[content.dataType];

              return {
                ...content,
                fill,
                color: colorsByFill[fill],
              };
            }),
          };
        }),
      };
    },
  },

  created() {
    this.setUpdatedChartData();
  },
};
</script>

<style scoped lang="scss">
.diagrams_list__item_body {
  display: flex;
  justify-content: center;
  width: 100%;

  border-bottom: 1px solid $app-primary-blue-lighter-color;
  .empty {
    padding: 10px 0;
  }

  pointer-events: none;
}
</style>

<style lang="scss">
.swiper-pagination.swiper-pagination-bullets {
  padding-bottom: 10px;
}

.swiper-pagination-bullet {
  background-color: $app-primary-blue-light-color;
}

.swiper-pagination-bullet-active {
  background-color: $app-green-color;
}
</style>
