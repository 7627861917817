import { projectsEnums } from "@/constants/enums/projects";
import {
  projectsStagesNames,
  projectStatusesNames,
} from "@/constants/projects/projectStatuses";

export function updateHomeDashboardData(dashboardData) {
  let updatedData = {};

  dashboardData.forEach(({ key, data }) => {
    updatedData[key] = Object.entries(data)
      .filter(
        ([key]) =>
          key !== "count" &&
          key !== "notificationCount" &&
          key !== "applicationnCount"
      )
      .reduce((acc, [key, value]) => {
        if (typeof value !== "object") {
          if (
            key !== "count" &&
            key !== "applicationnCount" &&
            key !== "notificationCount"
          ) {
            if (acc.default) {
              acc.default.push({ [key]: value });
            } else {
              acc.default = [{ [key]: value }];
            }
          }
        } else {
          const tabName = key;
          Object.entries(value)
            .filter(([key]) => key !== "count")
            .forEach(([key, value]) => {
              if (acc[tabName]) {
                acc[tabName].push({ [key]: value });
              } else {
                acc[tabName] = [{ [key]: value }];
              }
            });
        }

        return acc;
      }, {});
  });

  return Object.entries(updatedData).map(([key, value]) => {
    return {
      title: projectsEnums[key],
      type: key,
      tabs: Object.entries(value).map(([key, data]) => {
        return {
          tabTitle: projectsStagesNames[key],
          tabType: key,
          content: data.map((item) => {
            let key = null;
            let value = null;

            Object.entries(item).forEach(([itemKey, itemValue]) => {
              key = itemKey;
              value = itemValue;
            });

            return {
              dataType: key,
              dataTitle: projectStatusesNames[key],
              value,
            };
          }),
        };
      }),
    };
  });
}
