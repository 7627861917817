export function getCookie() {
  return {
    auth() {
      return document.cookie.replace(
        /(?:(?:^|.*;\s*)ssid_agreement\s*=\s*([^;]*).*$)|^.*$/,
        "$1"
      );
    },
    all() {
      const cookies = document.cookie.split(";");
      let ret = "";
      for (let i = 1; i <= cookies.length; i++) {
        ret += i + " - " + cookies[i - 1] + "<br>";
      }
      return ret;
    },
  };
}
