<template>
  <app-i-o-s-picker
    :options="simplifiedOptions"
    :startIndex="selectedIdx"
    @change="changeHandler"
  />
</template>

<script>
import { dateTypesEnum } from "@/constants/enums/date";
import AppIOSPicker from "@/components/ui/IOSPicker";

export default {
  name: "PickerDataProvider",
  components: { AppIOSPicker },
  props: {
    options: { type: Array, required: true },
    type: {
      type: String,
      default: dateTypesEnum.month,
      validator: (type) => Object.keys(dateTypesEnum).indexOf(type) !== -1,
    },
  },

  data() {
    return {
      selectedIdx: this.options.indexOf(
        this.options.find(({ selected }) => selected)
      ),
    };
  },

  computed: {
    simplifiedOptions() {
      return this.options.map(({ title }) => title);
    },

    selectedIdxByStore() {
      return this.options.indexOf(
        this.options.find(({ selected }) => selected)
      );
    },
  },

  watch: {
    selectedIdxByStore(idx) {
      this.selectedIdx = idx;
    },
  },

  methods: {
    changeHandler(idx) {
      this.selectedIdx = idx;

      this.$emit("setDate", {
        type: this.type,
        value: idx,
      });
    },
  },
};
</script>

<style scoped></style>
