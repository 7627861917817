import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Date from "../views/Date.vue";
import Category from "../views/categories/Category";
import Project from "../views/project/Project";
import { DEFAULT_TITLE } from "../constants/base";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/date",
    name: "date",
    component: Date,
  },
  {
    path: "/category/:categoryType/:categoryName",
    name: "category",
    component: Category,
  },
  {
    path: "/category/:categoryType/:categoryName/:subCategoryName",
    name: "subCategory",
    component: Category,
  },
  {
    path: "/project/:id",
    name: "project",
    component: Project,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.params.title || DEFAULT_TITLE;
  });
});

export default router;
