export function defaultParamsController(params) {
  const acceptedParams = [
    "month",
    "quarter",
    "year",
    "firstDate",
    "lastDate",
    "startDate",
    "finalDate",
    "userRoleId",
    "userCompanyId",
  ];

  if (Object.keys(params).length) {
    return Object.fromEntries(
      Object.entries(params).filter(([key]) => {
        return acceptedParams.indexOf(key) !== -1;
      })
    );
  }

  return {};
}
