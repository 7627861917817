<template>
  <li :class="{ app_list__item: !!to === false }">
    <router-link class="app_list__item" v-if="to" :to="to">
      <div class="app_list__item_content">
        <slot />
      </div>
      <div class="app_list__item_icon">
        <img src="@/assets/img/icons/chevron-blue.svg" alt=">" />
      </div>
    </router-link>

    <slot v-else />
  </li>
</template>

<script>
export default {
  name: "AppListItem",
  props: { to: { type: String } },
};
</script>

<style scoped lang="scss">
.app_list__item {
  font-size: 1rem;
  padding: 1em $app-layout-x-padding;
  line-height: 1.46;
  position: relative;
  color: $app-white-color;
  text-decoration: none;
  &:before {
    content: "";
    width: calc(100% - #{$app-layout-x-padding});
    height: 0.5px;
    background: $app-primary-blue-lighter-color;

    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .app_list__item_content {
    padding-right: 15px;
  }

  &_icon {
    $width: 7px;
    width: $width;
    position: absolute;
    top: calc(50% - #{$width / 2});
    right: $app-layout-x-padding;

    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
