export function getBarDiagramOptions({
  diagramType,
  diagramGroupType,
  seriesNative,
  seriesTotal,
  fills,
  categories,
  onClick = () => {},
}) {
  return {
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      radialBar: {
        startAngle: 90,
        endAngle: 360,

        hollow: {
          margin: 0,
          size: "50px",
          background: "transparent",
          image: undefined,
        },

        dataLabels: {
          showOn: "always",
          value: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 300,
            color: "white",
            offsetY: 7,
          },
          total: {
            show: true,
            label: seriesTotal,
            color: "white",
            fontFamily: "Roboto",
            fontSize: "34px",
            fontWeight: 300,

            formatter() {
              return [diagramType, diagramGroupType];
            },
          },
        },

        track: {
          show: false,
          margin: 3,
          strokeWidth: "10%",
        },
      },
    },
    test: seriesTotal,
    labels: categories,
    colors: fills,
    fill: {
      colors: fills,
    },

    legend: {
      show: true,
      floating: true,
      showForSingleSeries: true,
      showForZeroSeries: true,
      showForNullSeries: true,
      position: "left",
      fontFamily: "Roboto",
      offsetX: 102,
      offsetY: 192,
      fontSize: "12px",
      inverseOrder: true,

      onItemHover: {
        highlightDataSeries: true,
      },

      labels: {
        useSeriesColors: true,
      },

      itemMargin: {
        vertical: 0.6,
      },
      formatter: function (seriesName, opts) {
        return `${seriesNative[opts.seriesIndex]}  ${seriesName}`;
      },
      markers: {
        width: 0,
        height: 0,
      },
    },
    chart: {
      type: "radialBar",
      height: 270,
      events: {
        click() {
          onClick();
        },
      },
      offsetY: 0,
    },
  };
}
