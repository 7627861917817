import { projectTypesNames } from "@/constants/enums/projects";
import { BASE_URL_PROD } from "@/constants/base";

const { notification, statement, revision, decision } = projectTypesNames;

export const apiRoutes = {
  projectsInfo: "/api/MonitoringDashboards/GetProjectTotalCountInfo",

  dashboard: {
    [notification]: "/api/MonitoringDashboards/GetProjectNotificationStageInfo",
    [statement]: "/api/MonitoringDashboards/GetProjectApplicationStageInfo",
    [revision]: "/api/MonitoringDashboards/GetProjectExpertReviewInfo",
    [decision]: "/api/MonitoringDashboards/GetProjectNPSDecisionInfo",
  },

  projectsList: {
    path: "/api/Projects/filters",
    baseURL: BASE_URL_PROD,
  },
  projectDetails(id) {
    return {
      baseURL: BASE_URL_PROD,
      path: `/api/Projects/${id}`,
    };
  },
  authInformation: {
    path: `${BASE_URL_PROD}/api/Account`,
  },
};
