export const dateTypesEnum = {
  quarter: "quarter",
  month: "month",
  year: "year",
  period: "period",
};

const { quarter, month, year, period } = dateTypesEnum;

export const dateTypesTabsEnum = {
  [month]: "Месяц",
  [quarter]: "Квартал",
  [year]: "Год",
  [period]: "Период",
};
