<template>
  <label
    :for="id"
    class="app_badge_radio"
    :class="{ 'app_badge_radio--checked': checked }"
  >
    <input
      v-bind="$attrs"
      class="app_badge_radio__input"
      type="radio"
      :id="id"
      :checked="checked"
      @change="changeHandler"
    />

    <span class="app_badge_radio__name">{{ title }}</span>
  </label>
</template>

<script>
export default {
  name: "AppBadgeRadio",
  props: {
    id: { type: String, Number, required: true },
    title: { type: String, required: true },
    checked: { type: Boolean, default: false },
  },

  inheritAttrs: false,

  methods: {
    changeHandler(evt) {
      this.$emit("change", evt);
    },
  },
};
</script>

<style scoped lang="scss">
.app_badge_radio {
  position: relative;

  display: block;
  overflow: hidden;

  color: $app-white-color;
  font-size: fontSize(sm);
  &__input {
    visibility: hidden;
    pointer-events: none;
    width: 0;
    height: 0;
    position: absolute;
  }
  &__name {
    line-height: 0.857;
    display: block;
    border-radius: 9px;
    padding: 0.857em 1.14em;

    background: $app-badge-default;
    color: $app-white-color;
    transition-property: color, background-color;
    transition-duration: 0.2s;
  }

  &--checked {
    .app_badge_radio__name {
      background: $app-badge-selected;
      color: $app-white-color;
    }
  }
}
</style>
