<template>
  <div class="date_wrapper">
    <app-tabs
      class="app_date_picker"
      :class="{ dark: darkTabs }"
      :tabs="tabs"
      :selected="selectedTab"
      :parent="$route.name"
      @select="selectTabHandler"
    >
      <template #tabsContent>
        <picker-data-provider type="month" :options="months" v-if="selectedTab === 0" @setDate="sateDateHandler" :key="1234" />

        <picker-data-provider :options="quarters" type="quarter" v-if="selectedTab === 1" @setDate="sateDateHandler" :key="12345" />
        <picker-data-provider :options="years" type="year" v-if="selectedTab === 2" @setDate="sateDateHandler" :key="123456" />

        <app-period-picker type="period" v-if="selectedTab === 3" :selectedPeriod="periods" @setDate="sateDateHandler" />
      </template>
    </app-tabs>
    <div class="container app_date_picker__button_wrapper">
      <app-button class="app_date_picker__button" type="primary" @click="apply"> Применить </app-button>
    </div>
  </div>
</template>

<script>
import AppTabs from '@/components/ui/Tabs/Tabs';
import AppPeriodPicker from '@/components/Date/PeriodPicker';
import AppButton from '@/components/ui/AppButton';
import PickerDataProvider from '@/components/Date/PickerDataProvider';

import { dateApi } from '@/store/date';
import { dateTypesTabsEnum } from '@/constants/enums/date';
import { mapGetters } from 'vuex';

const { currentMonth, currentDay, currentYear, currentQuarter } = dateApi;

export default {
  name: 'Date',
  components: {
    PickerDataProvider,
    AppButton,
    AppPeriodPicker,
    AppTabs,
  },

  data() {
    return {
      tabs: Object.values(dateTypesTabsEnum).map((type) => type),
      selectedTab: 0,
      month: currentMonth,
      period: currentDay,
      year: currentYear,
      quarter: currentQuarter,
    };
  },

  created() {
    const types = Object.keys(dateTypesTabsEnum);
    this.selectedTab = types.indexOf(types.find((type) => type === this.selectedDateType));
  },

  computed: {
    ...mapGetters(['months', 'years', 'quarters', 'selectedDateType']),
    periods() {
      return this.$store.getters.periods;
    },

    darkTabs() {
      return this.selectedTab === 3;
    },
  },

  methods: {
    selectTabHandler(idx) {
      this.selectedTab = idx;
    },

    sateDateHandler({ type, value }) {
      this[type] = value;
    },

    apply() {
      const dateType = Object.keys(dateTypesTabsEnum)[this.selectedTab];
      const date = this[dateType];

      this.$store.commit('setDateType', dateType);

      this.$store.commit('setDate', {
        [dateType]: date,
      });

      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.date_wrapper {
  position: relative;

  flex: 1;
  display: flex;
  max-width: 100%;

  background: linear-gradient(#161d40, #1f2559);
}
.app_date_picker {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 120px;
  max-width: 100%;

  .app_tabs_list {
    position: sticky;
    z-index: 999999;
    top: 0;
  }
  &__button {
    width: 100%;

    &_wrapper {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      background: $app-primary-blue-dark-color;
      z-index: 9999;
      padding-bottom: $app-safe-area;
    }
  }
}

.app_date_picker.dark {
  .app_tabs_list {
    height: 78px;

    color: $app-white-color;
  }
}
</style>
