<template>
  <div class="app-chart-wrapper" @click.stop>
    <div class="chart-inner" :style="{ height: `290px` }">
      <vue-apex-charts
        width="100%"
        parentHeightOffset="0"
        type="radialBar"
        height="290px"
        :options="options"
        :series="percentSeries"
        class="vue-apexcharts-block"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { getBarDiagramOptions } from "@/components/Charts/config/barDiagramConfiguration";
import { getBarDiagramOptionsSecond } from "@/components/Charts/config/barDiagramConfigurationSecond";
const colors = ["#2EB4B4", "#E3EAFB", "#F86A6F"];
const titles = ["реализовано", "в работе", "просрочено"];
const labelColors = ["#FFFFFF", "#1C2A5D", "#FFFFFF"];
export default {
  name: "AppBarDiagram",
  components: { VueApexCharts },
  props: {
    diagramType: { type: String },
    diagramGroupType: { type: String },
    series: { type: Array, required: true },
    fillArray: { type: Array, default: () => colors },
    colorsArray: { type: Array, default: () => labelColors },
    categoriesNames: { type: Array, default: () => titles },
  },
  data() {
    return {
      showTooltip: false,
      baseHeight: 37,
    };
  },
  computed: {
    options() {
      const settings = {
        diagramType: this.diagramType,
        diagramGroupType: this.diagramGroupType,
        seriesNative: this.series,
        seriesTotal: this.seriesTotal(),
        colors: this.colorsArray,
        fills: this.fillArray,
        categories: this.categoriesNames,
      };

      const tempSettings = settings;
      tempSettings.seriesNative = this.series;

      if (this.series.length === 3) {
        return getBarDiagramOptions(settings);
      } else if (this.series.length === 2) {
        return getBarDiagramOptionsSecond(settings);
      } else if (this.series.length === 1) {
        return getBarDiagramOptionsSecond(settings);
      } else {
        return getBarDiagramOptions(settings);
      }
    },
    percentSeries() {
      const newArray = [];

      const maxValue = Math.max(...this.series);

      function convertToPercent(value) {
        return (value * 100) / maxValue;
      }

      this.series.forEach((element) => {
        let tempElement = element;

        newArray.push(Math.round(convertToPercent(tempElement)) || 0);
      });

      return newArray;
    },

    height() {
      const length = this.series.length;
      return this.baseHeight * length + 45;
    },

    wrapperHeight() {
      const length = this.series.length;
      return `${30 * length + 12 * (length - 1)}`;
    },
  },
  methods: {
    seriesTotal() {
      let total = 0;

      this.series.forEach((element) => {
        total += element;
      });

      return total;
    },
  },
};
</script>

<style lang="scss">
.apexcharts-tooltip.apexcharts-theme-light {
  background: transparent;
  border: none;
}

.app-chart-wrapper {
  position: relative;
}

.chart-inner {
  display: flex;
  width: 264px;
  margin-top: -4px;
}

.apexcharts-legend {
  width: 100%;

  font-size: 12px;
}

.apexcharts-legend-text {
  white-space: nowrap;

  font-size: 12px;
}

.apexcharts-inner {
  transform: translate(0, 0) !important;
}

.apexcharts-radialbar .apexcharts-series.apexcharts-radial-series {
  transform: rotate(90deg) translate(15px, -266px);
}
</style>
