<template>
  <div class="app-ios-picker">
    <div class="swiper-container" ref="container">
      <swiper
        @slideChange="changeHandler"
        ref="swiperInstance"
        :options="swiperOptions"
      >
        <swiper-slide v-for="opt of options" :key="opt">{{ opt }}</swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "AppIOSPicker",
  props: {
    options: { type: Array, required: true },
    startIndex: { type: Number, default: 0 },
  },
  components: {
    Swiper,
    SwiperSlide,
  },

  directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperOptions: {
        direction: "vertical",
        slidesPerView: 3,
        spaceBetween: 0,
        initialSlide: this.startIndex,
        centeredSlides: true,
      },
    };
  },

  methods: {
    changeHandler() {
      this.$emit("change", this.$refs.swiperInstance.$swiper.activeIndex);
    },
  },

  swiper() {
    return this.$refs.swiperInstance.$swiper;
  },

  mounted() {
    this.changeHandler();
  },
};
</script>

<style lang="scss" scoped>
.swiper-container {
  height: 132px;

  overflow: hidden;
  width: 100%;
}
.swiper-slide {
  height: 44px !important;
  text-align: center;
  padding-top: 11px;
  padding-bottom: 11px;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: fontSize(xl);

  color: $app-primary-font-color;

  &-active {
    color: $app-green-color;
    font-size: fontSize(xxl-large);
  }

  &:before {
    content: "";
    background: $app-primary-blue-lighter-color;

    width: calc(100% - #{$app-layout-x-padding});
    height: 0.5px;
    position: absolute;
    bottom: 1px;
    right: 0;
  }

  &-prev {
    transition-delay: 0s;
  }

  &-active {
    transition-delay: 0.05s;
  }
  &-next {
    transition-delay: 0.1s;
  }
}

.app-ios-picker {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
