<template>
  <div class="horizontal_scroll__wrapper">
    <h2 class="horizontal_scroll__title" v-if="title">{{ title }}</h2>
    <div class="horizontal_scroll__area" :class="classObject">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "HorizontalScrollArea",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  computed: {
    classObject() {
      return {
        "horizontal_scroll__area--date": this.type === "date",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.horizontal_scroll {
  &__wrapper {
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__title {
    display: block;
    width: 100%;
    margin-bottom: 24px;
    padding: 0 16px;

    text-align: left;
    font-size: 16px;
    color: #909599;
    font-weight: 400;
    line-height: 1.15;
  }

  &__area {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    min-width: max-content;
    padding-left: $app-layout-x-padding;
    padding-right: $app-layout-x-padding;

    &--date {
      padding-left: 0;
      padding-right: 0;
      padding: 2px;
      margin: 0 16px;

      background-color: $app-primary-blue-color;
      border-radius: 9px;
    }

    & > * {
      min-width: max-content;
    }
  }
}
</style>
