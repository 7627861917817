<template>
  <div
    v-bind="$attrs"
    class="app-stroke-button"
    :class="{ icon }"
    @click="(evt) => $emit('click', evt)"
  >
    <slot />
    <div class="icon">
      <slot name="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppStrokeButton",
  inheritAttrs: false,
  props: {
    icon: { type: Boolean, default: false },
  },
};
</script>

<style scoped lang="scss">
.app-stroke-button {
  $padding-x: 0.857em;
  font-size: fontSize(sm);
  padding: 0.8em $padding-x;
  border-radius: $border-radius;
  border: 0px solid $app-primary-color;
  color: $app-green-color;
  transition: background-color 0.2s;
  position: relative;
  line-height: 1;

  &.icon {
    width: 40px;
    height: 40px;
    border-radius: $border-radius / 2 + 1;
    padding: 0;
    .icon img {
      width: auto;
      fill: $app-green-color;
      object-fit: contain;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:active {
    background: $app-primary-light-color;
  }
}
</style>
