const projectRoute = (type) => `/category/${type}`;
const projectCategoryRoute = (type, category) =>
  `/category/${type}/${category}`;
const projectSubCategoryRoute = (type, category, subcategory) =>
  `/category/${type.toLowerCase()}/${category.toLowerCase()}/${subcategory.toLowerCase()}`;
import { v4 as uuidv4 } from "uuid";

export const initialProjectsState = [
  {
    id: uuidv4(),
    type: "notification",
    route: projectRoute("notification"),
    innerRoutes: {
      developProjectsCount: {
        id: uuidv4(),
        route: projectCategoryRoute("notification", "development"),
        innerRoutes: {
          implemented: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "development",
              "implemented"
            ),
          },
          inProgress: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "development",
              "inprogress"
            ),
          },
          expired: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "development",
              "expired"
            ),
          },
        },
      },
      revisionProjectsCount: {
        id: uuidv4(),
        route: projectCategoryRoute("notification", "revision"),
        innerRoutes: {
          implemented: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "revision",
              "implemented"
            ),
          },
          inProgress: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "revision",
              "inprogress"
            ),
          },
          expired: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "notification",
              "revision",
              "expired"
            ),
          },
        },
      },
    },
  },

  {
    id: uuidv4(),
    type: "statement",
    route: projectRoute("statement"),
    innerRoutes: {
      developProjectsCount: {
        id: uuidv4(),
        route: projectCategoryRoute("statement", "development"),
        innerRoutes: {
          onEvaluation: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "development",
              "onevaluation"
            ),
          },
          inProgress: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "development",
              "inprogress"
            ),
          },
          forRevision: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "development",
              "forrevision"
            ),
          },
        },
      },
      revisionProjectsCount: {
        id: uuidv4(),
        route: projectCategoryRoute("statement", "revision"),
        innerRoutes: {
          onEvaluation: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "revision",
              "onevaluation"
            ),
          },
          inProgress: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "revision",
              "inprogress"
            ),
          },
          forRevision: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "revision",
              "forrevision"
            ),
          },
        },
      },
      refinementProjectsCount: {
        id: uuidv4(),
        route: projectCategoryRoute("statement", "rework"),
        innerRoutes: {
          onEvaluation: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "rework",
              "onevaluation"
            ),
          },
          inProgress: {
            id: uuidv4(),
            route: projectSubCategoryRoute("statement", "rework", "inprogress"),
          },
          forRevision: {
            id: uuidv4(),
            route: projectSubCategoryRoute(
              "statement",
              "rework",
              "forrevision"
            ),
          },
        },
      },
    },
  },

  {
    id: uuidv4(),
    type: "revision",
    route: projectRoute("revision"),
    innerRoutes: {
      default: {
        id: uuidv4(),
        route: projectCategoryRoute("revision", "main"),
        innerRoutes: {
          success: {
            id: uuidv4(),
            route: projectSubCategoryRoute("revision", "main", "success"),
          },
          remarks: {
            id: uuidv4(),
            route: projectSubCategoryRoute("revision", "main", "remarks"),
          },
        },
      },
    },
  },

  {
    id: uuidv4(),
    type: "decision",
    route: projectRoute("decision"),
    innerRoutes: {
      default: {
        id: uuidv4(),
        route: projectCategoryRoute("decision", "main"),
        innerRoutes: {
          approved: {
            id: uuidv4(),
            route: projectSubCategoryRoute("decision", "main", "approved"),
          },
          forRevision: {
            id: uuidv4(),
            route: projectSubCategoryRoute("decision", "main", "forrevision"),
          },
          rejected: {
            id: uuidv4(),
            route: projectSubCategoryRoute("decision", "main", "rejected"),
          },
        },
      },
    },
  },
];
