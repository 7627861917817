export const icons = {
  previousMonth: `
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.58562 0.26797C6.98993 0.591417 7.05548 1.18138 6.73203 1.58569L2.20055 7.25004L6.73203 12.9144C7.05548 13.3187 6.98992 13.9087 6.58562 14.2321C6.18131 14.5555 5.59135 14.49 5.2679 14.0857L0.2679 7.83569C-0.0060142 7.49329 -0.00601417 7.00678 0.2679 6.66438L5.2679 0.414383C5.59135 0.0100748 6.18131 -0.0554765 6.58562 0.26797Z" fill="#4071FF"/>
            </svg>

          `,
  nextMonth: `
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.414383 14.732C0.0100749 14.4086 -0.0554765 13.8186 0.26797 13.4143L4.79945 7.74996L0.267969 2.08562C-0.0554771 1.68131 0.0100743 1.09135 0.414382 0.7679C0.81869 0.444453 1.40865 0.510005 1.7321 0.914313L6.7321 7.16431C7.00601 7.50671 7.00601 7.99322 6.7321 8.33562L1.7321 14.5856C1.40865 14.9899 0.818691 15.0555 0.414383 14.732Z" fill="#4071FF"/>
            </svg>
          `,
};
