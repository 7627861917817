import { api } from "@/main";
import { initialProjectsState } from "@/store/modules/home/initialProjectsState";
import { apiProjectCountEnum, projectsEnums } from "@/constants/enums/projects";
import { dateTypesEnum } from "@/constants/enums/date";
import { updateHomeDashboardData } from "@/store/modules/home/updateHomeDashboardData";

const { notification, statement, revision, decision } = projectsEnums;

export default {
  state: {
    projects: initialProjectsState,
    dashboard: {
      [notification]: null,
      [statement]: null,
      [revision]: null,
      [decision]: null,
    },
    dashboardData: [],
  },

  getters: {
    projects(state) {
      return state.projects;
    },
    dashboardData({ dashboardData }) {
      return dashboardData;
    },
  },
  mutations: {
    setDashboardData(state, dashboardData) {
      state.dashboardData = updateHomeDashboardData(dashboardData);
    },

    setProjectsCount(state, countData) {
      Object.entries(countData).forEach(([key, count]) => {
        const project = state.projects.find(
          ({ title }) => title === apiProjectCountEnum[key]
        );

        if (!project) {
          console.warn("Project by type ", key, " not found");
          return;
        }

        project.count = count;
      });
    },
  },

  actions: {
    async fetchHomeSummary({ commit, getters }) {
      const authInformation = await api.getAuthInformation();

      commit("setAuthInformation", authInformation);

      const { selectedDateType, selectedDate, userRoleId, userCompanyId } =
        getters;

      let date = {
        [selectedDateType]: selectedDate,
      };

      if (selectedDateType === dateTypesEnum.period) {
        date = {
          ...selectedDate,
        };
      }
      const { data: projectsCountData, error: projectsCountError } =
        await api.getProjectTotalCountInfo({
          userRoleId,
          userCompanyId,
          ...date,
        });

      if (!projectsCountError) {
        commit("setProjectsCount", projectsCountData);
      }

      const { data: dashboardData, error: dashboardError } =
        await api.getHomeDashboardSummary({
          userRoleId,
          userCompanyId,
          ...date,
        });

      if (!dashboardError) {
        commit("setDashboardData", dashboardData);
      }
    },
  },
};
