function arrayFormatter(value) {
  if (!Array.isArray(value)) {
    return value;
  }

  if (!value.length) {
    return " - ";
  }

  return value.map(({ name }) => name).join(", ");
}

export const projectSummaryTypes = {
  stage_name: {
    name: "Стадия",
  },
  status_name: {
    name: "Статус",
  },
  serviceType_name: {
    name: "Связь с перечнем",
  },
  createdOn: {
    name: "Дата создания уведомления",
    formatter(value) {
      const dateValue = new Date(value);

      if (!dateValue) {
        return value;
      }

      const day = dateValue.getDate();
      const month = dateValue.getMonth() + 1;
      const year = dateValue.getFullYear();

      return `${day}.${month}.${year}`;
    },
  },
  name: {
    name: "Наименование КР",
  },
  mkb_name: {
    name: "Наименование заболения",
  },
  mkbCodes: {
    name: "Код МКБ-10",
    formatter(value) {
      if (!Array.isArray(value)) {
        return value?.code || " - ";
      }

      return value.map(({ code }) => code).join(", ");
    },
  },
  developer_name: {
    name: "Наименование разработчика",
  },

  developers: {
    name: "Соавторы",
    formatter: arrayFormatter,
  },
};
