import Vue from "vue";
import Vuex from "vuex";
import categories from "@/store/categories";
import date from "@/store/date";
import { getCookie } from "@/utilles/getCookie";
import home from "@/store/home";
import projects from "@/store/projects";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cookie: null,
    loading: true,
    apiError: "",
    authInformation: {
      userRoleId: null,
      userCompanyId: null,
    },

    requests: [],
  },
  getters: {
    requests({ requests }) {
      return requests;
    },
    apiError({ apiError }) {
      return apiError;
    },

    getCookie(state) {
      if (state.cookie) {
        return state.cookie;
      }

      const { auth, all } = getCookie();
      state.cookie = { auth, all };
      return state.cookie;
    },

    loading({ loading }) {
      return loading;
    },

    userRoleId(state) {
      if (
        state.authInformation.userRoleId ||
        state.authInformation.userRoleId === 0
      ) {
        return state.authInformation.userRoleId;
      } else {
        return null;
      }
    },

    userCompanyId(state) {
      if (
        state.authInformation.userCompanyId ||
        state.authInformation.userCompanyId === 0
      ) {
        return state.authInformation.userCompanyId;
      } else {
        return null;
      }
    },
  },
  mutations: {
    toggleRequest(state, { request, status = true }) {
      if (!status) {
        state.requests = state.requests.filter((req) => req !== request);
        return;
      }

      state.requests.push(request);
    },
    setApiError(state, err) {
      state.apiError = err;
    },
    setLoading(state, status) {
      state.loading = status;
    },

    setCookie(state, cookie) {
      state.cookie = cookie;
    },

    setAuthInformation(state, payload) {
      state.authInformation.userRoleId = payload.data.role.id;
      state.authInformation.userCompanyId = payload.data.company.id;
    },
  },
  actions: {},
  modules: {
    categories,
    date,
    home,
    projects,
  },
});
