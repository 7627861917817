<template>
  <button
    class="app_button"
    :class="`app_button--${type}`"
    @click="(evt) => $emit('click', evt)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    type: {
      type: String,
      default: "primary",
      validator: (t) => ["primary"].indexOf(t) !== -1,
    },
  },
};
</script>

<style scoped lang="scss">
.app_button {
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  color: inherit;

  font-size: fontSize(lg);

  padding: 0.94em 1.5em;
  border-radius: 100px;
  letter-spacing: 0.5px;

  &--primary {
    background: $app-primary-color;
    color: $app-white-color;

    &:active {
      background: #3c62d2;
    }
  }
}
</style>
