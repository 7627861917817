import { dateTypesEnum } from "@/constants/enums/date";
import { DateApi } from "@/utilles/date/DateApi";
import { formatDate } from "@/utilles/date/formatDate";
import { getMonthNumberByName } from "@/utilles/date/getMonthNumberByName";
export const dateApi = new DateApi();

const { month, quarter, year, period } = dateTypesEnum;

export const updatedDateTypes = {
  month: "months",
  year: "years",
  period: "period",
  quarter: "quarters",
};

export default {
  state: {
    selectedDateType: year,
    selectedDateOptions: dateApi.getDateDateByType(dateTypesEnum.month),

    months: dateApi.getDateDateByType(month),
    period: dateApi.getDateDateByType(period),
    years: dateApi.getDateDateByType(year),
    quarters: dateApi.getDateDateByType(quarter),
    path: "/",
  },

  mutations: {
    setDateType(state, type) {
      if (Object.keys(dateTypesEnum).indexOf(type) === -1) {
        console.error("Date type: ", type, " is not defined");
        return;
      }

      state.selectedDateType = type;
    },

    setDate(state, { period, quarter, year, month }) {
      if (period !== undefined) {
        state.period = [...period];
      }

      if (quarter !== undefined) {
        state.quarters.forEach((q, idx) => {
          q.selected = idx === quarter;
        });
      }

      if (year !== undefined) {
        state.years.forEach((y, idx) => {
          y.selected = idx === year;
        });
      }

      if (month !== undefined) {
        state.months.forEach((m, idx) => {
          m.selected = idx === month;
        });
      }
    },

    setCurrentPath(state, path) {
      state.path = path;
    },
  },

  getters: {
    selectedDateType(state) {
      return state.selectedDateType;
    },

    selectedDate(state) {
      const updatedType = updatedDateTypes[state.selectedDateType];

      const date =
        state.selectedDateType === period
          ? state[updatedType]
          : state[updatedType].find(({ selected }) => selected);

      let queryMainPath, queryCategoryPath;

      if (date[0] && date[1]) {
        queryMainPath = {
          firstDate: `${date[0].getFullYear()}-${
            date[0].getMonth() + 1
          }-${date[0].getDate()}`,
          lastDate: `${date[1].getFullYear()}-${
            date[1].getMonth() + 1
          }-${date[1].getDate()}`,
        };

        queryCategoryPath = {
          startDate: `${date[0].getFullYear()}-${
            date[0].getMonth() + 1
          }-${date[0].getDate()}`,
          finalDate: `${date[1].getFullYear()}-${
            date[1].getMonth() + 1
          }-${date[1].getDate()}`,
        };
      }

      switch (state.selectedDateType) {
        case month:
          return getMonthNumberByName(date.title);

        case year:
          return date.title;

        case quarter:
          return parseInt(date.title);

        case period:
          if (state.path.split("/")[1] === "category") {
            return queryCategoryPath;
          } else {
            return queryMainPath;
          }

        default:
          return "";
      }
    },

    activeDateOptions(state) {
      return (format) => {
        const updatedType = updatedDateTypes[state.selectedDateType];

        if (updatedType !== period) {
          return state[updatedType];
        }

        if (format) {
          const start = formatDate(state[updatedType][0], {
            includeDayName: false,
          });

          let end = formatDate(state[updatedType][1], {
            includeDayName: false,
          });

          end = start === end ? "" : ` - ${end}`;

          return [
            {
              title: `${start}${end}`,
              selected: true,
            },
          ];
        }

        return state[updatedType];
      };
    },

    years(state) {
      return state[updatedDateTypes.year];
    },

    quarters(state) {
      return state[updatedDateTypes.quarter];
    },

    months(state) {
      return state[updatedDateTypes.month];
    },

    periods(state) {
      return state[updatedDateTypes.period];
    },
  },
};
