<template>
  <div class="container">
    <div class="app_toolbar">
      <router-link to="/date">
        <div class="app_toolbar__row">
          <div class="app_toolbar__col app_toolbar__col_lg app_toolbar__title">
            <span :class="classObjectText">
              <span v-show="isMainPage">Статистика за </span
              >{{ selectedOption }}
            </span>
          </div>
          <div class="app_toolbar__col app_toolbar__col_sm">
            <app-stroke-button :icon="true">
              <template #icon>
                <img
                  src="@/assets/img/icons/calendar_new.svg"
                  alt="Календарь"
                />
              </template>
            </app-stroke-button>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import AppStrokeButton from "@/components/ui/StrokeButton";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppToolbar",
  components: { AppStrokeButton },

  computed: {
    ...mapGetters(["selectedDateType"]),

    options() {
      return this.$store.getters.activeDateOptions(true);
    },
    selectedOption() {
      if (!this.options) {
        return 0;
      }

      return this.options.find(({ selected }) => selected).title;
    },
    classObjectText() {
      return { app_toolbar__span_white: this.isMainPage };
    },
    isMainPage() {
      return this.$route.name === "Home";
    },
  },

  methods: {
    ...mapMutations(["setDate"]),
    dateSelectHandler(idx) {
      this.setDate({ [this.selectedDateType]: idx });
      this.$emit("change");
    },
  },
};
</script>

<style scoped lang="scss">
$sm-col-width: 40px;
.app_toolbar {
  padding-top: $app-layout-x-padding;
  padding-bottom: $app-layout-x-padding;
  &__row {
    display: flex;

    border-bottom: 1px solid $app-primary-blue-lighter-color;
  }
  &__col {
    &_lg {
      $indent: 20px;
      flex: 1;
      margin-right: $indent;
      max-width: calc(100% - #{$sm-col-width} - #{$indent});
    }
    &_sm {
      width: $sm-col-width;
    }
  }

  &__span_white {
    color: $app-white-color;
  }

  &__title {
    display: flex;
    align-items: center;

    color: $app-green-color;
  }
}
</style>
