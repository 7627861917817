import { updatedMonths } from "@/constants/date/months";
import { getCurrentYear } from "@/utilles/date/getCurrentDate";
import { APP_LOCALE } from "@/constants/base";

export function formatDate(
  date,
  { includeYear = true, includeDayName = true } = {}
) {
  const dateInstance = new Date(date);
  const dayNumber = dateInstance.getDate();

  let _includeYear =
    includeYear ?? dateInstance.getUTCFullYear() !== getCurrentYear();

  const dayName = includeDayName
    ? `${new Intl.DateTimeFormat(APP_LOCALE, {
        weekday: "short",
      }).format(dateInstance)}, `
    : "";

  const monthNumber = dateInstance.getMonth();
  const monthName = updatedMonths[monthNumber];
  const year = _includeYear ? dateInstance.getFullYear() : "";

  return `${dayName}${dayNumber} ${monthName} ${year}`;
}
