import axios from "axios";
import { BASE_URL } from "@/constants/base";
axios.defaults.withCredentials = true;
export function createAxios() {
  const $axios = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
  });

  return $axios;
}
