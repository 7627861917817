import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuescroll from "vuescroll";
import Tab from "vue-swipe-tab";
import { createAxios } from "@/plugins/axios";
import { Api } from "@/api/Api";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

export const $axios = createAxios();
export const api = new Api($axios);

Vue.use(VueToast);
Vue.use(Tab);
Vue.config.productionTip = false;
Vue.use(vuescroll, {
  ops: {
    rail: {
      background: "transparent",
      opacity: 0,
      size: "6px",
      specifyBorderRadius: false,
      gutterOfEnds: null,
      gutterOfSide: "2px",
      keepShow: false,
    },

    bar: {
      background: "#D3E3FF",
      size: "6px",
      specifyBorderRadius: "12px",
      keepShow: true,
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
