import {
  projectFiltersEnums,
  projectsEnums,
  projectTabsEnum,
} from "@/constants/enums/projects";
import { v4 as uuidv4 } from "uuid";

function categoryExist(category) {
  return Object.keys(projectsEnums).indexOf(category) !== -1;
}
const projectKeys = [
  "Стадия",
  "Статус",
  "Тип подачи",
  "Дата создания уведомления",
  "Наименование КР",
  "Наименование заболения",
  "Код МКБ-10",
  "Наименование разработчика",
  "Соавторы",
];
export default {
  state: {
    notification: new Array(20).fill("Уведомление № 524"),
    statement: new Array(20).fill("Заявление № 751"),
    revision: new Array(20).fill("Уведомление № 524"),
    decision: new Array(20).fill("Уведомление № 524"),

    project: projectKeys.map((key) => ({
      id: uuidv4(),
      title: key,
      value: "Значение",
    })),

    tabs: Object.entries(projectTabsEnum)
      .filter((category) => category)
      .map(([category, tabs]) => {
        return {
          category,
          tabs: Object.entries(tabs).map(([id, title], idx) => {
            return {
              id,
              title,
              selected: idx === 0,
            };
          }),
        };
      })
      .filter(({ tabs }) => tabs.length),

    filters: Object.entries(projectFiltersEnums).map(([category, filters]) => {
      return {
        category,
        filters: Object.entries(filters).map(([id, title], idx) => {
          return {
            id,
            title,
            selected: idx === 0,
          };
        }),
      };
    }),
  },

  getters: {
    tabs(state) {
      return (category) => {
        if (!category) {
          return state.tabs;
        }

        const selectedTabsCategory = state.tabs.find(
          (tabsCategory) => tabsCategory.category === category
        );

        if (selectedTabsCategory) {
          return [selectedTabsCategory];
        }

        return [];
      };
    },

    filters(state) {
      return (category) => {
        if (category) {
          return state.filters.find((filter) => filter.category === category);
        }

        if (state.filters) {
          return state.filters;
        }
      };
    },

    list(state) {
      return (category) => {
        return state[category];
      };
    },

    projectDetails(state) {
      return () => {
        return state.project;
      };
    },
  },
  mutations: {
    selectTab(state, { category, id }) {
      const tabsCategory = state.tabs.find((tab) => tab.category === category);

      if (tabsCategory) {
        tabsCategory.tabs.forEach((tab) => {
          tab.selected = tab.id === id;
        });
      } else {
        console.error("tab by category ", category, " not found");
      }
    },

    selectFilter(state, { category, id }) {
      const filter = state.filters.find(
        (filter) => filter.category === category
      );

      if (filter) {
        filter.filters.forEach((filter) => {
          filter.selected = filter.id === id;
        });
      } else {
        console.error("filter by category ", category, " not found");
      }
    },

    setList(state, { category, list }) {
      if (!categoryExist(category)) {
        console.error("Unexpected project category ", category);

        return;
      }

      state[category] = list;
    },
  },

  actions: {
    async fetchProjectDetails({ getters }, id) {
      return getters.projectDetails(id);
    },

    async fetchProjectList({ commit, getters }, category) {
      if (!categoryExist(category)) {
        console.error("Unexpected project category ", category);

        return null;
      }

      const list = getters.list(category);

      if (list && list.length) {
        return list;
      }

      console.log("fetching..");
      commit("setList", { category, list: [] });
    },
  },
};
