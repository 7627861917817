<template>
  <ul class="app_list">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "AppList",
};
</script>

<style>
.app_list {
  width: 100%;
}
</style>
