import { api } from "@/main";
import { updateProjectSummary } from "@/store/modules/projects/updateProjectSummary";
import { dateTypesEnum } from "@/constants/enums/date";

export default {
  state: {
    projectsList: [],
    projectSummary: {},
  },

  mutations: {
    setProjectSummary(state, summary) {
      state.projectSummary = updateProjectSummary(summary);
    },

    setProjectsList(state, list) {
      state.projectsList = list?.map(({ name, id }) => ({ name, id })) || [];
    },
  },

  getters: {
    projectSummary(state) {
      return state.projectSummary;
    },
    projectsList(state) {
      return state.projectsList;
    },
  },

  actions: {
    async fetchProjectsList(
      { commit, getters },
      { stageId = 0, statusId = 0, typeId = 0, ...params }
    ) {
      const authInformation = await api.getAuthInformation();

      commit("setAuthInformation", authInformation);

      let { selectedDateType, selectedDate, userRoleId, userCompanyId } =
        getters;

      let date = {
        [selectedDateType]: selectedDate,
      };

      if (selectedDateType === dateTypesEnum.period) {
        date = {
          ...selectedDate,
        };
      }
      const { data: projectsListData } = await api.getProjectsLst({
        stageId,
        statusId,
        typeId,
        userRoleId,
        userCompanyId,
        ...params,
        ...date,
      });

      commit("setProjectsList", projectsListData);
    },

    async fetchProjectSummary({ commit }, id) {
      const { data, error } = await api.getProjectsDetails(id);

      if (!error) {
        commit("setProjectSummary", data);
      }
    },
  },
};
