import {
  getCurrentMonth,
  getCurrentYear,
  getQuarter,
  getToday,
} from "@/utilles/date/getCurrentDate";
import { dateTypesEnum } from "@/constants/enums/date";
import { months } from "@/constants/date/months";
import { years } from "@/constants/date/years";
import { quarters } from "@/constants/date/quarters";

export class DateApi {
  constructor() {
    this.currentMonth = getCurrentMonth();
    this.currentDay = getToday();
    this.currentYear = getCurrentYear();
    this.currentQuarter = getQuarter();
    this.dateType = dateTypesEnum.month;

    this.types = {
      [dateTypesEnum.month](selected = this.currentMonth) {
        return months.map((month, idx) => {
          return {
            title: month,
            selected: selected === idx,
          };
        });
      },

      [dateTypesEnum.year](selected = this.currentYear) {
        const currentIdx = years().indexOf(selected);
        return years().map((year, idx) => ({
          title: year,
          selected: idx === currentIdx,
        }));
      },

      [dateTypesEnum.quarter](selected = this.currentQuarter) {
        return quarters.map((quarter) => {
          return {
            title: `${quarter} квартал`,
            selected: parseInt(selected) === quarter,
          };
        });
      },

      [dateTypesEnum.period](period) {
        const today = new Date(Date.now()).toISOString();

        return period ?? [today, today];
      },
    };
  }

  getDateDateByType(type, selectedDate) {
    const isExistedType = Object.keys(dateTypesEnum).indexOf(type) !== -1;

    if (!isExistedType) {
      console.error("Date type: ", type, " is not defined");
      return [];
    }

    return this.types[type].bind(this)(selectedDate);
  }
}
