<template>
  <app-list class="project_details_list">
    <app-list-item
      v-for="{ key, title, value } of projectSummary"
      class="project_details_list__item"
      :key="key"
    >
      <div class="project_details_list__item_key">{{ title }}</div>
      <div class="project_details_list__item_value">{{ value }}</div>
    </app-list-item>
  </app-list>
</template>

<script>
import AppList from "@/components/ui/List/AppList";
import AppListItem from "@/components/ui/List/AppListItem";
import { mapGetters } from "vuex";
export default {
  name: "Project",
  props: {
    title: String,
  },
  data() {
    return {
      details: [],
    };
  },

  components: { AppListItem, AppList },

  computed: {
    ...mapGetters(["projectSummary"]),
    id() {
      return this.$route.params.id;
    },
  },

  async created() {
    if (this.title) {
      document.title = this.title;

      // Don't delete!!! Need to webview (emitting title on enter detail page)!
      if (window.webkit) {
        window.webkit.messageHandlers.title.postMessage(this.title);
      } else {
        console.log(`title ${this.title}`);
      }

      this.details = await this.$store.dispatch("fetchProjectDetails", this.id);
      await this.$store.dispatch("fetchProjectSummary", this.id);
    } else {
      this.details = await this.$store.dispatch("fetchProjectDetails", this.id);
      await this.$store.dispatch("fetchProjectSummary", this.id);

      const title = this.projectSummary.find((project) => {
        return project.key === "name";
      }).value;

      document.title = title;

      if (window.webkit) {
        window.webkit.messageHandlers.title.postMessage(this.title);
      } else {
        console.log(`title ${this.title}`);
      }
    }
  },

  beforeDestroy() {
    if (window.webkit) {
      window.webkit.messageHandlers.title.postMessage("");
    }
  },
};
</script>

<style scoped lang="scss">
.project_details_list {
  &__item {
    display: flex;
    justify-content: space-between;

    &_key {
      flex: 1;
      word-break: break-word;
      padding-right: 10px;
    }
    &_value {
      flex-shrink: 2;
      max-width: 50%;
      word-break: break-word;
      text-align: right;
      color: $app-primary-font-color;
    }
  }
}
</style>
