<template>
  <div class="app_tabs">
    <horizontal-scroll-area
      class="app_tabs_list"
      :class="classObjectTab"
      v-if="tabs"
      :type="parent"
    >
      <div
        class="app_tabs_list__item"
        :class="{
          'app_tabs_list__item--selected': idx === selected,
          'app_tabs_list__item--date': parent === 'date',
          'app_tabs_list__item--date--selected':
            parent === 'date' && idx === selected,
        }"
        v-for="(tab, idx) of tabs"
        :key="tab"
        @click="selectHandler(idx)"
      >
        <img
          class="app_tabs_list__icon"
          :src="require(`@/assets/img/icons/tabs/${tabsTypes[idx]}.svg`)"
          v-if="tabsTypes"
        />
        <div>
          {{ tab }}
        </div>
      </div>
    </horizontal-scroll-area>

    <slot name="betweenContent" />
    <slot name="tabsContent" />
  </div>
</template>

<script>
import HorizontalScrollArea from "@/components/ui/HorizontalScrollArea";

export default {
  name: "AppTabs",
  components: { HorizontalScrollArea },
  props: {
    tabs: { type: [Array, null] },
    tabsTypes: { type: [Array, null] },
    selected: { type: [Number, null], default: null },
    parent: { type: [String, null] },
  },

  computed: {
    classObjectTab() {
      return {
        "app_tabs_list--fixed": this.parent === "category",
        "app_tabs_list--top": this.parent === "date",
      };
    },
  },

  watch: {
    selected(selected) {
      this.selectedTab = selected || this.tabs[0];
    },
  },

  data() {
    return {
      selectedTab: this.selected ?? 0,
    };
  },

  methods: {
    selectHandler(idx) {
      this.$emit("select", idx);
    },
    getTabsIcon(tabIndex) {
      return `@/assets/img/icons/tabs/${tabIndex}.svg`;
    },
  },
};
</script>

<style lang="scss">
.app_tabs_list {
  color: $app-primary-font-color;

  &--fixed {
    position: fixed;
    bottom: 0;
    z-index: 1000;

    background-color: $app-primary-blue-dark-color;
  }

  &--top {
    color: $app-white-color;

    background-color: $app-primary-blue-dark-color;

    border-radius: 9px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.57em 1.5em;
    min-width: max-content;

    text-align: center;
    line-height: 1.42;
    font-size: fontSize(sm);
    letter-spacing: -0.24px;
    color: $app-primary-color;

    opacity: 1;

    transition: color 0.2s;

    &--selected {
      color: $app-primary-color;

      background-color: $app-primary-blue-color;

      opacity: 1;
    }

    &--date {
      color: $app-white-color;

      opacity: 1;

      &--selected {
        background-color: $app-primary-blue-light-color;
        border-radius: 9px;
      }
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
    margin: 0 auto;
  }
}
</style>
