import { ApiBase, DataObject } from "@/api/ApiBase";
import { apiRoutes } from "@/api/routes";
import { defaultParamsController } from "@/api/modules/defaultParamsController";
import { projectsParamsController } from "@/api/modules/projects/projectsParamsController";
import { dashboardParamsController } from "@/api/modules/dashboardParamsController";

export class Api extends ApiBase {
  constructor(...arg) {
    super(...arg);
  }

  async getProjectTotalCountInfo(params) {
    const data = defaultParamsController(params);

    return await this.request({
      url: apiRoutes.projectsInfo,
      method: "post",
      payload: data,
    });
  }

  async getHomeDashboardSummary(params) {
    const data = dashboardParamsController(params);

    const result = {};

    const promises = Object.entries(apiRoutes.dashboard).map(
      async ([type, url]) => {
        return new Promise((resolve, reject) => {
          // data[type].isMobRequest = true;

          this.request({
            url,
            method: "post",
            payload: data[type],
          })
            .then((data) => {
              result[type] = data;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    );

    await Promise.all(promises);

    let err = null;

    const updatedData = Object.entries(result).map(([key, { data, error }]) => {
      err = error;
      return {
        key,
        data,
      };
    });

    return new DataObject({
      data: updatedData,
      error: err,
    });
  }

  async getProjectsDetails(id) {
    const { baseURL, path } = apiRoutes.projectDetails(id);

    return await this.request({
      url: `${baseURL}${path}`,
      method: "get",
      withCredentials: true,
      baseUrl: baseURL,
    });
  }

  async getProjectsLst(params) {
    const filter = projectsParamsController(params);
    const { baseURL, path } = apiRoutes.projectsList;

    return await this.request({
      url: `${baseURL}${path}`,
      method: "post",
      withCredentials: true,
      payload: filter,
      baseUrl: baseURL,
    });
  }

  async getAuthInformation() {
    const { path } = apiRoutes.authInformation;

    return await this.request({
      url: path,
      method: "get",
    });
  }
}
