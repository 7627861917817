<template>
  <div class="app_tabs">
    <horizontal-scroll-area
      class="app_tabs_list"
      :class="classObjectTab"
      v-if="tabs"
      :type="parent"
    >
      <router-link
        :to="setRoute(idx)"
        replace
        v-for="(tab, idx) of tabs"
        :key="tab"
      >
        <div
          class="app_tabs_list__item"
          :class="{
            'app_tabs_list__item--selected': tabsNames[idx] === selectedTabName,
            'app_tabs_list__item--date': parent === 'date',
            'app_tabs_list__item--date--selected':
              parent === 'date' && tabsNames[idx] === selectedTabName,
          }"
          @click="selectHandler(idx)"
        >
          <img
            class="app_tabs_list__icon"
            :src="require(`@/assets/img/icons/tabs/${tabsNames[idx]}.svg`)"
            v-if="tabsNames"
          />
          <div>
            {{ tab }}
          </div>
        </div>
      </router-link>
    </horizontal-scroll-area>

    <slot name="betweenContent" />
    <slot name="tabsContent" />
  </div>
</template>

<script>
import HorizontalScrollArea from "@/components/ui/HorizontalScrollArea";

export default {
  name: "AppTabs",
  components: { HorizontalScrollArea },
  props: {
    categoryName: { type: String },
    categoryType: { type: String },
    tabs: { type: [Array, null] },
    tabsNames: { type: [Array, null] },
    selected: { type: [Number, null], default: null },
    selectedTabName: { type: [String, null], default: null },
    selectedFilter: {
      type: String,
    },
    parent: { type: [String, null] },
  },

  computed: {
    selectedFilterName() {
      return this.selectedFilter || this.$route.params.subCategoryName;
    },
    classObjectTab() {
      return {
        "app_tabs_list--fixed": this.parent === "subCategory",
        "app_tabs_list--top": this.parent === "date",
      };
    },
  },

  watch: {
    selected(selected) {
      this.selectedTab = selected || this.tabsNames[0];
    },
  },

  data() {
    return {
      selectedTab: this.selected ?? 0,
    };
  },

  methods: {
    setRoute(tabIndex) {
      return `/category/${this.categoryType}/${this.tabsNames[tabIndex]}/${this.selectedFilterName}`;
    },
    selectHandler(idx) {
      this.$emit("select", this.tabsNames[idx]);
    },
  },
};
</script>

<style lang="scss">
.app_tabs_list {
  color: $app-primary-font-color;

  &--fixed {
    position: fixed;
    bottom: 0;
    z-index: 1000;

    background-color: $app-primary-blue-dark-color;
  }

  &--top {
    color: $app-white-color;

    background-color: $app-primary-blue-dark-color;

    border-radius: 9px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0.57em 1.5em;
    min-width: max-content;

    text-align: center;
    line-height: 1.42;
    font-size: fontSize(sm);
    letter-spacing: -0.24px;
    color: $app-primary-color;

    opacity: 1;

    transition: color 0.2s;

    &--selected {
      color: $app-primary-color;

      background-color: $app-primary-blue-color;

      opacity: 1;
    }

    &--date {
      color: $app-white-color;

      opacity: 1;

      &--selected {
        background-color: $app-primary-blue-light-color;
        border-radius: 9px;
      }
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
    margin: 0 auto;
  }
}
</style>
