import store from "@/store";
import { BASE_URL } from "@/constants/base";
export function DataObject({ data = null, error = null }) {
  this.data = data;
  this.error = error;

  return this;
}

export class ApiBase {
  constructor(http) {
    this.http = http;
  }
  errorHandler(err) {
    console.error(err);
    store.commit("setApiError", err);
  }

  async request({ url, method = "get", payload = null, baseUrl = BASE_URL }) {
    let data = {};

    try {
      store.commit("setLoading", true);

      if (method === "post") {
        data = payload;
      }

      const { data: response } = await this.http({
        url,
        method,
        data,
        baseURL: baseUrl,
      });

      setTimeout(() => {
        store.commit("setLoading", false);
      }, 500);

      return new DataObject({ data: response });
    } catch (err) {
      this.errorHandler(err);

      setTimeout(() => {
        store.commit("setLoading", false);
      }, 500);
      return new DataObject({ error: err });
    }
  }
}
