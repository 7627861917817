const acceptedParams = [
  "firstDate",
  "lastDate",
  "month",
  "quarter",
  "year",
  "stageId",
  "statusId",
  "typeId",
  "expertiseResultId",
  "npsDecisionId",
  "startDate",
  "finalDate",
  "isPortalDiscussion",
  "userRoleId",
  "userCompanyId",
];

export function projectsParamsController(params) {
  const {
    stageId = 0,
    statusId = 0,
    typeId = 0,
    npsDecisionId = 0,
    expertiseResultId = 0,
    isPortalDiscussion = false,
    userRoleId = false,
    userCompanyId = false,
  } = params;

  const _params = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => {
      const checkRoles = key === "userRoleId" || key === "userCompanyId";

      if (checkRoles && value === false) {
        return false;
      } else if (checkRoles) {
        return (
          acceptedParams.indexOf(key) !== -1 && value !== false && value !== ""
        );
      }

      if (typeof value === "boolean" && value === false) {
        return false;
      } else if (typeof value === "boolean" && value === true) {
        return (
          acceptedParams.indexOf(key) !== -1 && value !== false && value !== ""
        );
      }

      if (Array.isArray(value) && value[0] === 0) {
        return false;
      }

      return acceptedParams.indexOf(key) !== -1 && value !== 0 && value !== "";
    })
  );

  const types = [
    "stageId",
    "statusId",
    "typeId",
    "expertiseResultId",
    "npsDecisionId",
    "isPortalDiscussion",
    "userRoleId",
    "userCompanyId",
  ];

  [
    stageId,
    statusId,
    typeId,
    expertiseResultId,
    npsDecisionId,
    isPortalDiscussion,
    userRoleId,
    userCompanyId,
  ].forEach((param, idx) => {
    const isArray = Array.isArray(param);
    const isBoolean = typeof param === "boolean";
    const resultParam = isArray || isBoolean ? param : [param];

    if (resultParam[0] !== 0 && resultParam !== false) {
      _params[types[idx]] = resultParam;
    }
  });

  if (_params.userRoleId) {
    _params.userRoleId = _params.userRoleId[0];
  }

  if (_params.userCompanyId) {
    _params.userCompanyId = _params.userCompanyId[0];
  }

  // _params.isMobRequest = true;

  return _params;
}
