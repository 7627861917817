import { projectSummaryTypes } from "@/constants/projects/projectSummaryTypes";

export function updateProjectSummary(summary = {}) {
  return Object.entries(projectSummaryTypes).reduce(
    (acc, [key, { name, formatter }]) => {
      const updatedKey = key.split("_");

      let summaryData = summary[updatedKey[0]];

      if (updatedKey.length > 1) {
        updatedKey.slice(1).forEach((key) => {
          if (summaryData) {
            summaryData = summaryData[key];
          }
        });
      }

      if (formatter) {
        summaryData = formatter(summaryData);
      }
      acc.push({
        key,
        title: name,
        value: summaryData,
      });

      return acc;
    },
    []
  );
}
