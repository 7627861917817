const green = "#1DEBBA";
const red = "#F86A6F";
const blue = "#548dff";

export const colorsByFill = {
  [green]: "#1DEBBA",
  [red]: "#1C2A5D",
  [blue]: "#548dff",
};

export const projectStatusesNames = {
  realizedProjectsCount: "Реализовано",
  inWorkNotStatementActualProjectsCount: "В работе",
  notRealizedNotStatementNotActualProjectsCount: "Просрочено",
  sentToCEKKMPStatusCount: "На оценку",
  inProgress: "В работе",
  sentForRevisionStatusCount: "На доработку",
  successWithoutСommentsProjectsCount: "Успешно",
  successWithСommentsProjectsCount: "С замечаниями",
  approvedProjectsCount: "Одобрено",
  revisionProjectsCount: "На доработку",
  rejectedProjectsCount: "Отклонено",
};

export const projectsStagesNames = {
  developProjectsCount: "Разработка",
  revisionProjectsCount: "Пересмотр",
  refinementProjectsCount: "Доработка",
};

export const projectStatusFills = {
  realizedProjectsCount: green,
  inWorkNotStatementActualProjectsCount: blue,
  notRealizedNotStatementNotActualProjectsCount: red,
  sentToCEKKMPStatusCount: green,
  inProgress: blue,
  sentForRevisionStatusCount: red,
  successWithoutСommentsProjectsCount: green,
  successWithСommentsProjectsCount: blue,
  approvedProjectsCount: green,
  revisionProjectsCount: blue,
  rejectedProjectsCount: red,
};
