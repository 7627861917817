import { v4 as uuidv4 } from "uuid";

export const projectTypesNames = {
  notification: "notification",
  statement: "statement",
  revision: "revision",
  decision: "decision",
};

export const projectsEnums = {
  notification: "Уведомления",
  statement: "Заявления",
  revision: "Экспертная оценка КР",
  decision: "Решение НПС",
};

export const projectEnumsTotal = {
  notification: "Уведомлений",
  statement: "Заявлений",
  revision: "Оценок",
  decision: "Решений НПС",
};

export const projectEnumsGroups = {
  developProjectsCount: "В разработке",
  revisionProjectsCount: "На пересмотре",
  refinementProjectsCount: "На доработке",
  default: "",
};

const { notification, statement, revision, decision } = projectsEnums;

export const apiProjectCountEnum = {
  notificationCount: notification,
  applicationCount: statement,
  expertReviewCount: revision,
  npsDecisionCount: decision,
};

export const projectFiltersEnums = {
  notification: {
    implemented: "Реализовано",
    inProgress: "В работе",
    expired: "Просрочено",
  },

  statement: {
    onEvaluation: "На оценке",
    inProgress: "В работе",
    forRevision: "На доработку",
  },

  revision: {
    success: "Успешно",
    remarks: "С замечаниями",
  },

  decision: {
    approved: "Одобрено",
    forRevision: "На доработку",
    rejected: "Отклонено",
  },
};

export const projectTabsEnum = {
  notification: {
    development: "Разработка",
    revision: "Пересмотр",
  },

  statement: {
    development: "Разработка",
    revision: "Пересмотр",
    rework: "Доработка",
  },

  revision: {},
  decision: {},
};

export const stagesIdByType = {
  notification: {
    types: [
      {
        typeId: 1,
        name: "development",
        title: "Разработка",
      },
      {
        typeId: 2,
        name: "revision",
        title: "Пересмотр",
      },
    ],

    statuses: [
      {
        title: "Реализовано",
        name: "implemented",
        statusId: 6,
        isPortalDiscussion: true,
        id: uuidv4(),
      },
      {
        title: "В работе",
        name: "inprogress",
        statusId: [3, 4, 31, 32],
        id: uuidv4(),
      },
      { title: "Просрочено", name: "expired", statusId: 35, id: uuidv4() },
    ],
  },

  statement: {
    stageId: 2,
    types: [
      { typeId: [7, 1], name: "development", title: "Разработка" },
      { typeId: 9, name: "rework", title: "Доработка" },
      { typeId: [8, 2], name: "revision", title: "Пересмотр" },
    ],
    statuses: [
      {
        title: "На оценке",
        name: "onevaluation",
        statusId: 13,
        stageId: 3,
        id: uuidv4(),
      },
      { title: "В работе", name: "inprogress", statusId: 10, id: uuidv4() },
      {
        title: "На доработку",
        name: "forrevision",
        statusId: 12,
        id: uuidv4(),
      },
    ],
  },

  revision: {
    stageId: [3, 4, 5, 6],
    statuses: [
      {
        id: uuidv4(),
        name: "success",
        title: "Успешно",
        statusId: 0,
        stageId: 0,
        extraParams: { expertiseResultId: 1 },
      },
      {
        id: uuidv4(),
        name: "remarks",
        title: "С замечаниями",
        statusId: 0,
        stageId: 0,
        extraParams: { expertiseResultId: 2 },
      },
    ],
  },

  decision: {
    stageId: [5, 6],
    statuses: [
      {
        id: uuidv4(),
        name: "approved",
        title: "Одобрено",
        statusId: 0,
        extraParams: { npsDecisionId: 1 },
      },
      {
        id: uuidv4(),
        name: "forrevision",
        title: "На доработку",
        statusId: 0,
        extraParams: { npsDecisionId: 2 },
      },
      {
        id: uuidv4(),
        name: "rejected",
        title: "Отклонено",
        statusId: 0,
        extraParams: { npsDecisionId: 3 },
      },
    ],
  },
};
