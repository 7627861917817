var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category_page"},[_c('app-tabs-route',{attrs:{"categoryName":_vm.$route.params.categoryName,"categoryType":_vm.$route.params.categoryType,"tabs":_vm.tabsList,"tabsTypes":_vm.typesList,"tabsNames":_vm.namesList,"selectedFilter":_vm.selectedFilterName,"selectedTabName":_vm.selectedTabName,"parent":_vm.$route.name},on:{"select":_vm.selectTabHandler},scopedSlots:_vm._u([{key:"betweenContent",fn:function(){return [_c('div',{staticClass:"content-inner"},[_c('horizontal-scroll-area',{staticClass:"projects_filters"},[_c('div',{staticClass:"projects_filters__wrapper"},_vm._l((_vm.filters),function(ref){
var title = ref.title;
var id = ref.id;
var name = ref.name;
var selected = ref.selected;
return _c('app-badge-radio',{key:id,staticClass:"projects_filters__item",attrs:{"name":(_vm.category + "-filter"),"id":id,"title":title,"checked":selected},on:{"change":function($event){return _vm.filterHandler(id, name)}}})}),1)]),_c('app-toolbar')],1)]},proxy:true},{key:"tabsContent",fn:function(){return [_c('div',{staticClass:"projects_wrapper"},[(_vm.projectsList.length)?_c('app-list',_vm._l((_vm.projectsList),function(ref){
var name = ref.name;
var id = ref.id;
return _c('router-link',{key:id,attrs:{"to":{ name: 'project', params: { id: id, title: name } }}},[_c('app-list-item',[_c('p',[_vm._v(_vm._s(name))])])],1)}),1):_c('p',{staticClass:"empty"},[_vm._v("Нет данных")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }