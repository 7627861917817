export const dashboardParams = {
  notification: {
    statusId: [6],
    stageId: [1],
  },
  statement: {
    statusId: [6],
    stageId: [1],
    isPortalDiscussion: true,
  },
  revision: {
    statusId: [13],
    stageId: [3],
  },
  decision: {
    statusId: [13],
    stageId: [5, 6],
  },
};
