<template>
  <div class="page-content">
    <transition name="fade">
      <div class="overlay" v-if="showOverlay" @click="showOverlay = false"></div>
    </transition>

    <app-toolbar @change="toolbarDateChangeHandler" />

    <div class="home-diagram container">
      <dashboard-chart
        :chart-data="chart"
        v-for="chart in sortedDashboardData"
        :routes="setRoute(chart.type)"
        :key="`chart-${chart.type}`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppToolbar from '@/components/Layout/Toolbar';
import DashboardChart from '@/components/DashboardChart';
import { projectsEnums } from '@/constants/enums/projects';

export default {
  name: 'Home',
  components: {
    DashboardChart,
    AppToolbar,
  },
  methods: {
    async fetchSummary() {
      this.$store.commit('setCurrentPath', this.$route.path);
      await this.$store.dispatch('fetchHomeSummary');
    },

    toolbarDateChangeHandler() {
      this.fetchSummary();
    },
    setRoute(routeName) {
      return this.projects.find((route) => route.type === routeName);
    },
  },

  computed: {
    ...mapGetters(['dashboardData', 'projects']),
  },

  async created() {
    await this.fetchSummary();

    this.sortedDashboardData = [...this.dashboardData].sort(({ title: aTitle }, { title: bTitle }) => {
      const aPosition = this.sortCount.find(({ title }) => {
        return title.toLowerCase() === aTitle.toLowerCase();
      })?.position;

      const bPosition = this.sortCount.find(({ title }) => {
        return title.toLowerCase() === bTitle.toLowerCase();
      })?.position;

      return aPosition - bPosition;
    });

    this.sortedDashboardData[1].tabs.forEach((tab) => {
      tab.content.sort((a, b) => {
        if (a.dataType === 'sentToCEKKMPStatusCount' || b.dataType === 'sentToCEKKMPStatusCount') {
          return 1;
        } else {
          return -1;
        }
      });
    });
  },

  data() {
    return {
      sortedDashboardData: null,
      showOverlay: false,
      sortCount: Object.values(projectsEnums).map((title, idx) => ({
        title,
        position: idx,
      })),
    };
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(#000, 0.2);
  z-index: 9999999;
}

.diagrams_list__item_header {
  padding-top: 12px;
  padding-bottom: 16px;

  display: flex;
  align-items: center;
  .app_more_menu {
    margin-left: auto;
  }

  & > span {
    margin-left: 20%;
    font-size: 13px;
    color: $app-primary-font-color;
  }
  h2 {
    font-weight: 400;
    font-size: fontSize(lg);
  }
}
.diagrams_list__item_body {
  padding-bottom: 24px;
}
.project_categories {
  padding-bottom: $app-layout-x-padding * 2;
  padding-top: $app-layout-x-padding * 2;
  margin-top: -$app-layout-x-padding;
  &__item {
    width: 227px;
    height: 136px;
    background: $app-white-color;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    padding: 1em;

    color: inherit;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:not(:last-child) {
      margin-right: $app-layout-x-padding;
    }
    &_title {
      font-size: 1.2rem;
      line-height: 2;
    }
    &_count {
      font-size: 1.867rem;
    }

    &_title,
    &_count {
      margin: 0;
      font-weight: 400;
    }
  }
}
</style>
