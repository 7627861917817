const today = new Date(Date.now());
let currentMonth = 0;

export function getCurrentMonth() {
  currentMonth = today.getMonth();
  return today.getMonth();
}

export function getToday() {
  return today.getDate();
}

export function getCurrentYear() {
  return today.getFullYear();
}

export function getQuarter() {
  if (currentMonth === 0) {
    currentMonth = getCurrentMonth();
  }

  return Math.floor((currentMonth + 2) / 3);
}
